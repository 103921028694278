<template>
  <div class="col-lg-6 pt-lg-5 ps-lg-2 pe-lg-5 pb-0">
    <div class="row p-md-5 py-5">
      <div class="col-12 text-center gold_heading mb-2">
        <div class="row m-0 justify-content-center align-items-center">
          <div class="col-auto px-0 pt-1">
            <div class="gold-text"><ios-locate-icon class="inline-icon" /></div>
          </div>
          <div class="col-auto">
            CHANGE REGION
          </div>
        </div>
      </div>
      <div class="col-12 mt-4">
        <div class="row" v-if="allInternationalBranches">
          <div class="col-6 mb-3" v-for="(branch, key) in allInternationalBranches" :key="key">
            <Card image="image" :greenheading="branch.name" class="cursor" @click="selectBranch(key, branch)">
              <Image :imageName="branch.iconUrl" width="50" theClass="img-fluid round_image" />
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Image: defineAsyncComponent(() => import('@/components/Image.vue')),
    Card: defineAsyncComponent(() => import('@/views/Card.vue')),
    'ios-locate-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-locate.vue'))
  },
  name: 'SelectBranch',
  props: ['allInternationalBranches', 'selectedBranch', 'branchUpdated'],
  emits: ['update:selectedBranch', 'update:branchUpdated'],
  computed: {
    selectedBranchValue: {
      get () {
        return this.selectedBranch
      },
      set (value) {
        this.$emit('update:selectedBranch', value)
      }
    },
    branchUpdatedValue: {
      get () {
        return this.selectedBranch
      },
      set (value) {
        this.$emit('update:branchUpdated', value)
      }
    }
  },
  methods: {
    selectBranch (key, branch) {
      this.selectedBranchValue = branch
      this.branchUpdatedValue = branch
      setTimeout(() => {
        this.branchUpdatedValue = null
      }, 1000)
    }
  }
}
</script>

<style scoped>

.gold_heading {
  font-family: "quicksand_bold", Sans-serif;
  font-weight: 700;
  letter-spacing: 2.4px;
  color: var(--gold-color);
  font-size: 1rem;
  text-transform: uppercase;
}
.round_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
</style>
